import WebServiceRequest from './WebServiceRequest'

class CandleCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Candle/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class CandleUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Candle/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class CandleGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Candle/Get')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class CandleGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Candle/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class CandleGetBinanceDataRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Candle/GetBinanceData')
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
export {
    CandleCreateRequest,
    CandleUpdateRequest,
    CandleGetRequest,
    CandleGetAllRequest,
    CandleGetBinanceDataRequest
};
